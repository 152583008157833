import { ElmApp, PushSubscription, PushSubscriptionJSON } from '../types';

export const PUSH_API_KEY = 'BO0qZqgo4EAMjBgTwwGTysw7Nvze658CRmO-3kRjfJ3dNJrQZR0MxfyeMJwvoqbN-EuMRD2Vjk9Jc6SQ8PXW1Dc';

const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

const subscriptionGetter = new Promise<PushSubscription | null>((resolve, reject) => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((worker: ServiceWorkerRegistration) => {
            worker.pushManager.getSubscription().then((subscription) => {
                if (subscription) {
                    const json = subscription.toJSON();
                    try {
                        if (!json.endpoint || !json.keys?.auth || !json.keys?.p256dh) {
                            throw new Error('Invalid subscription data');
                        }
                        resolve({
                            endpoint: json.endpoint,
                            keys: {
                                auth: json.keys.auth,
                                p256dh: json.keys.p256dh
                            }
                        });
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    resolve(null);
                }
            });
        });
    } else {
        reject(null);
    }
});

export const initializePushApi = (app: ElmApp): void => {
    app.ports.push_api__subscribe.subscribe(() => {
        console.log("push_api__subscribe");
        subscriptionGetter
            .then((subscription) => {
                if (subscription) {
                    console.log('User IS subscribed.', subscription);
                } else {
                    console.log('User is NOT subscribed.');
                    const subscribeOptions = {
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(PUSH_API_KEY)
                    };
                    navigator.serviceWorker.ready.then((worker: ServiceWorkerRegistration) => {
                        worker.pushManager.subscribe(subscribeOptions).then((newSubscription) => {
                            console.log("Subscription promise resolved to", newSubscription);
                            const json = newSubscription.toJSON();
                            if (!json.endpoint || !json.keys?.auth || !json.keys?.p256dh) {
                                throw new Error('Invalid subscription data');
                            }
                            app.ports.push_api__subscription_created.send({
                                endpoint: json.endpoint,
                                keys: {
                                    auth: json.keys.auth,
                                    p256dh: json.keys.p256dh
                                }
                            });
                        });
                    });
                }
            });
    });

    app.ports.push_api__subscription_get.subscribe(() => {
        console.log("push_api__subscription_get");
        subscriptionGetter
            .then((subscription) => {
                if (subscription) {
                    app.ports.push_api__subscription_got.send(subscription);
                }
            });
    });

    app.ports.push_api__subscription_delete.subscribe(() => {
        console.log("push_api__subscription_delete");
        subscriptionGetter
            .then((subscription) => {
                if (subscription) {
                    console.log("push_api__subscription_delete subscription is NOT null");
                    navigator.serviceWorker.ready.then((worker: ServiceWorkerRegistration) => {
                        worker.pushManager.getSubscription().then((currentSubscription) => {
                            if (currentSubscription) {
                                currentSubscription.unsubscribe()
                                    .then((successful) => {
                                        console.log("Unsubscribed", successful);
                                        app.ports.push_api__subscription_deleted.send(subscription);
                                    })
                                    .catch((e) => {
                                        console.log("Couldn't unsubscribe", e);
                                    });
                            }
                        });
                    });
                } else {
                    console.log("push_api__subscription_delete subscription is null");
                }
            });
    });
}; 
import { ElmApp, WebSocketMessage } from '../types';

export class WebSocketManager {
    private ws: WebSocket | null = null;
    private messageQueue: WebSocketMessage[] = [];
    private reconnectAttempts = 0;
    private maxReconnectAttempts = 10;
    private reconnectTimeout: number | null = null;
    private isAuthenticated = true;
    private readonly wsUrl: string;
    private readonly app: ElmApp;
    private currentToken: string = '';

    constructor(wsUrl: string, app: ElmApp) {
        this.wsUrl = wsUrl;
        this.app = app;
    }

    public send(message: unknown): void {
        const wsMessage = message as WebSocketMessage;
        
        if (!this.isConnected()) {
            this.messageQueue.push(wsMessage);
            this.attemptReconnect();
        } else {
            try {
                this.ws?.send(JSON.stringify(wsMessage));
            } catch (error) {
                console.error('Failed to send message:', error);
                this.messageQueue.push(wsMessage);
                this.handleDisconnect();
            }
        }
    }

    public connect(token: string): void {
        this.isAuthenticated = true;
        this.reconnectAttempts = 0;
        this.currentToken = token;
        this.connectWebSocket();
    }

    private connectWebSocket(): void {
        if (this.ws?.readyState === WebSocket.CONNECTING) {
            return;
        }

        if (this.ws?.readyState === WebSocket.OPEN) {
            console.log('WebSocket already connected');
            return;
        }

        try {
            this.ws = new WebSocket(this.wsUrl);
            console.log('WebSocket connection being created');
            this.setupEventListeners();
        } catch (error) {
            console.error('Failed to create WebSocket connection:', error);
            this.handleDisconnect();
        }
    }

    private setupEventListeners(): void {
        if (!this.ws) return;

        this.ws.addEventListener('open', () => {
            console.log('WebSocket connected');
            this.reconnectAttempts = 0;
            this.send({ user_id: this.currentToken });
            this.processMessageQueue();
            this.app.ports.web_socket__on_open.send(null);
        });

        this.ws.addEventListener('close', (event) => {
            console.log('WebSocket closed', event.code, event.reason);
            this.handleDisconnect();
            this.app.ports.web_socket__on_close.send(this.currentToken);
            
            // Attempt to reconnect if it wasn't a clean close
            if (event.code !== 1000 && this.isAuthenticated) {
                this.attemptReconnect();
            }
        });

        this.ws.addEventListener('error', (error) => {
            console.error('WebSocket error:', error);
            this.handleDisconnect();
            
            // Attempt to reconnect on error if authenticated
            if (this.isAuthenticated) {
                this.attemptReconnect();
            }
        });

        this.ws.addEventListener('message', (event) => {
            try {
                if (event.data === "401") {
                    console.log('WebSocket unauthorized');
                    this.isAuthenticated = false;
                    this.handleUnauthorized();
                } else {
                    this.app.ports.web_socket__on_message.send(event.data);
                }
            } catch (error) {
                console.error('Error processing message:', error);
            }
        });

        this.app.ports.web_socket__kill.subscribe(() => this.cleanup());
    }

    private processMessageQueue(): void {
        while (this.messageQueue.length > 0 && this.isConnected()) {
            const message = this.messageQueue.shift();
            if (message) {
                try {
                    this.ws?.send(JSON.stringify(message));
                } catch (error) {
                    console.error('Failed to send queued message:', error);
                    this.messageQueue.unshift(message);
                    break;
                }
            }
        }
    }

    private handleDisconnect(): void {
        if (this.reconnectTimeout) {
            window.clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }

        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }

    private handleUnauthorized(): void {
        this.isAuthenticated = false;
        this.messageQueue = [];
        this.handleDisconnect();
    }

    private attemptReconnect(): void {
        if (this.reconnectAttempts >= this.maxReconnectAttempts || !this.isAuthenticated) {
            console.log('Max reconnection attempts reached or not authenticated');
            return;
        }

        const backoffTime = Math.floor(Math.random() * 5000);
        console.log(`Attempting to reconnect in ${backoffTime}ms (attempt ${this.reconnectAttempts + 1}/${this.maxReconnectAttempts})`);
        
        this.reconnectTimeout = window.setTimeout(() => {
            this.reconnectAttempts++;
            this.connectWebSocket();
        }, backoffTime);
    }

    private isConnected(): boolean {
        return this.ws?.readyState === WebSocket.OPEN;
    }

    private cleanup(): void {
        this.handleDisconnect();
        this.messageQueue = [];
        this.reconnectAttempts = 0;
        this.isAuthenticated = true;
        this.currentToken = '';
    }
} 
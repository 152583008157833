// import './BarcodeReader/index';

import { Elm } from './Main.elm';
import logo from './assets/logo.png';
import { ElmApp } from './types';
import { 
    readCredentials, 
    saveCredentials, 
    removeCredentials,
    readFilters,
    saveFilters,
    CUSTOMER_ORDER_STORAGE_KEY,
    DISPATCH_ORDER_STORAGE_KEY,
    RECEIVE_ORDER_STORAGE_KEY,
    SUPPLIER_ORDER_STORAGE_KEY,
    SUPPLIER_ORDERS_STORAGE_KEY
} from './services/storage';
import { WebSocketManager } from './services/websocket';
import { initializePushApi } from './services/push-api';
import { initializeServiceWorker } from './services/service-worker';

// Type declarations for Elm ports
interface ElmApp {
    ports: {
        credentials__sign_out: { subscribe: (callback: () => void) => void };
        credentials__write_to_local: { subscribe: (callback: (payload: { id_token: string; expire_at: number }) => void) => void };
        customer_order__read_filters: { subscribe: (callback: () => void) => void };
        customer_order__got_filters: { send: (filters: string) => void };
        customer_order__save_filters: { subscribe: (callback: (payload: string) => void) => void };
        dispatch_order__read_filters: { subscribe: (callback: () => void) => void };
        dispatch_order__got_filters: { send: (filters: string) => void };
        dispatch_order__save_filters: { subscribe: (callback: (payload: string) => void) => void };
        receive_order__read_filters: { subscribe: (callback: () => void) => void };
        receive_order__got_filters: { send: (filters: string) => void };
        receive_order__save_filters: { subscribe: (callback: (payload: string) => void) => void };
        supplier_order__read_filters: { subscribe: (callback: () => void) => void };
        supplier_order__got_filters: { send: (filters: string) => void };
        supplier_order__save_filters: { subscribe: (callback: (payload: string) => void) => void };
        supplier_orders__read_filters: { subscribe: (callback: () => void) => void };
        supplier_orders__got_filters: { send: (filters: string) => void };
        supplier_orders__save_filters: { subscribe: (callback: (payload: string) => void) => void };
        save_file: { subscribe: (callback: (tuple: [string, string]) => void) => void };
        general__navigate_to: { subscribe: (callback: (url: string) => void) => void };
        push_api__subscribe: { subscribe: (callback: () => void) => void };
        push_api__subscription_created: { send: (subscription: unknown) => void };
        push_api__subscription_delete: { subscribe: (callback: () => void) => void };
        push_api__subscription_deleted: { send: (subscription: unknown) => void };
        push_api__subscription_get: { subscribe: (callback: () => void) => void };
        push_api__subscription_got: { send: (subscription: unknown) => void };
        web_socket__connect: { subscribe: (callback: (token: string) => void) => void };
        web_socket__send: { subscribe: (callback: (message: unknown) => void) => void };
        web_socket__kill: { subscribe: (callback: () => void) => void };
        web_socket__on_open: { send: (data: null) => void };
        web_socket__on_close: { send: (token: string) => void };
        web_socket__on_message: { send: (data: string) => void };
    };
}

// C R E D E N T I A L S

const PUSH_API_KEY = 'BO0qZqgo4EAMjBgTwwGTysw7Nvze658CRmO-3kRjfJ3dNJrQZR0MxfyeMJwvoqbN-EuMRD2Vjk9Jc6SQ8PXW1Dc';

// A U T H

// A P P L I C A T I O N

const apiUrl: string = window.location.host === "app.texenergo.com"
    ? "https://v4.texenergo.com/api"
    : "http://localhost:3001/api";

const wsUrl: string = window.location.host === "app.texenergo.com"
    ? "wss://v4.texenergo.com/socket"
    : "ws://localhost:3001/socket";

const app = (Elm.Main.init({
    flags: {
        credentials: readCredentials(),
        apiUrl: apiUrl,
        images: {
            logo
        }
    }
}) as ElmApp);

// Credentials
app.ports.credentials__sign_out.subscribe(() => {
    removeCredentials();
});

app.ports.credentials__write_to_local.subscribe((payload: {
    id_token: string;
    expire_at: number;
}) => {
    saveCredentials(payload.id_token, payload.expire_at, () => {});
});

// Filters
app.ports.customer_order__read_filters.subscribe(() => {
    app.ports.customer_order__got_filters.send(readFilters(CUSTOMER_ORDER_STORAGE_KEY));
});

app.ports.customer_order__save_filters.subscribe((payload: string) => {
    saveFilters(CUSTOMER_ORDER_STORAGE_KEY, payload);
});

app.ports.dispatch_order__read_filters.subscribe(() => {
    app.ports.dispatch_order__got_filters.send(readFilters(DISPATCH_ORDER_STORAGE_KEY));
});

app.ports.dispatch_order__save_filters.subscribe((payload: string) => {
    saveFilters(DISPATCH_ORDER_STORAGE_KEY, payload);
});

app.ports.receive_order__read_filters.subscribe(() => {
    app.ports.receive_order__got_filters.send(readFilters(RECEIVE_ORDER_STORAGE_KEY));
});

app.ports.receive_order__save_filters.subscribe((payload: string) => {
    saveFilters(SUPPLIER_ORDERS_STORAGE_KEY, payload);
});

app.ports.supplier_order__read_filters.subscribe(() => {
    app.ports.supplier_order__got_filters.send(readFilters(SUPPLIER_ORDER_STORAGE_KEY));
});

app.ports.supplier_order__save_filters.subscribe((payload: string) => {
    saveFilters(SUPPLIER_ORDER_STORAGE_KEY, payload);
});

app.ports.supplier_orders__read_filters.subscribe(() => {
    app.ports.supplier_orders__got_filters.send(readFilters(SUPPLIER_ORDERS_STORAGE_KEY));
});

app.ports.supplier_orders__save_filters.subscribe((payload: string) => {
    saveFilters(SUPPLIER_ORDERS_STORAGE_KEY, payload);
});

// File handling
app.ports.save_file.subscribe((tuple: [string, string]) => {
    const fileName = tuple[0];
    const baseEncoded = tuple[1];

    const element = document.createElement('a');
    element.setAttribute('href', 'data:application/pdf;base64,' + encodeURIComponent(baseEncoded));
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
});

app.ports.general__navigate_to.subscribe((url: string) => {
    window.open(url);
});

// WebSocket
const wsManager = new WebSocketManager(wsUrl, app);

app.ports.web_socket__send.subscribe((message: unknown) => {
    wsManager.send(message);
});

app.ports.web_socket__connect.subscribe((token: string) => {
    wsManager.connect(token);
});

// Push API
initializePushApi(app);

// Service Worker
initializeServiceWorker();

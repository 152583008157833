export const initializeServiceWorker = (): void => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register('../ServiceWorker.ts').then(
            (registration) => {
                console.log("Registration", registration);
                registration.update();
            },
            (error) => {
                console.error(`Service worker registration failed: ${error}`);
            },
        );
        new Worker('../ServiceWorker.ts');
    }
}; 
export const CREDENTIALS_STORAGE_KEY = 'credentials';
export const CUSTOMER_ORDER_STORAGE_KEY = 'customer_order_filters';
export const DISPATCH_ORDER_STORAGE_KEY = 'dispatch_order_filters';
export const RECEIVE_ORDER_STORAGE_KEY = 'receive_order_filters';
export const SUPPLIER_ORDER_STORAGE_KEY = 'supplier_order_filters';
export const SUPPLIER_ORDERS_STORAGE_KEY = 'supplier_orders_filters';

export const readCredentials = (): null | string => 
    localStorage.getItem(CREDENTIALS_STORAGE_KEY);

export const saveCredentials = (credentials: string, expireAt: number, cb: () => void): void => {
    localStorage.setItem(CREDENTIALS_STORAGE_KEY, credentials);
};

export const removeCredentials = (): void => {
    localStorage.removeItem(CREDENTIALS_STORAGE_KEY);
};

export const readFilters = (key: string): string => 
    localStorage.getItem(key) || "";

export const saveFilters = (key: string, nextFilters: string): void => {
    localStorage.setItem(key, nextFilters);
}; 